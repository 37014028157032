<template>
    <div class="c-smartspy" v-if="isShopSelected">
        <v-title title="SmartSpy tasks"></v-title>
        <title-box>
            <div class="row">
                <div class="col-9">
                    <h1>SmartSpy tasks</h1>
                </div>
                <div class="col-3 text-right">
                    <mercur-button v-if="hasPermission('MerchantCentral/createSmartSpyTask')" @click="newTaskDialog.isOpen = true" class="btn btn-primary text-uppercase">
                        <i class="fas fa-plus"></i> Create new task
                    </mercur-button>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <template v-if="hasPermission('MerchantCentral/listSmartSpyTasks')">
                <div class="row mt-3" v-if="catalogProducts.length > 0">
                    <div class="col-3">
                        <pretty-select
                            placeholder="Catalog product"
                            customPlaceholder="Select an option"
                            v-model="catalogProduct"
                            :options="catalogProducts"
                            :get-option-label="item => item.productName"
                            :get-option-key="item => item.catalogProductId"
                            :prevent-text-wrap="true"
                            :shrunk-label="true"
                        ></pretty-select>
                    </div>
                    <div class="col-3">
                        <pretty-select
                            placeholder="Catalog product variant"
                            customPlaceholder="Select an option"
                            v-model="catalogProductVariantId"
                            :options="catalogProductVariants"
                            :get-option-label="item => item.productName"
                            :get-option-key="item => item.catalogProductId"
                            :reduce="item => item.catalogProductId"
                            :prevent-text-wrap="true"
                            :shrunk-label="true"
                        ></pretty-select>
                    </div>
                    <div class="col-3">
                        <country-selector
                            v-model="countryCode"
                            customPlaceholder="Select an option"
                            reducer="countryShortCode"
                            :shrunk-label="true"
                        />
                    </div>
                    <div class="col-3">
                        <pretty-select
                            placeholder="Status"
                            customPlaceholder="Select an option"
                            v-model="status"
                            :options="taskStatuses"
                            :prevent-text-wrap="true"
                            :shrunk-label="true"
                        ></pretty-select>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <pagination :pagination="pagination" :enable-refresh="true" @change="changeAmountOfItems" />
                    </div>
                </div>
                <div class="mb-4">
                    <merchant-data-table
                        v-if="catalogProductVariantsLookup.length > 0"
                        class="shadow"
                        :options="options"
                        :url="url"
                        ref="table"
                        @paginationSet="updatePagination"
                        :isNotApplicationBound="true"
                    ></merchant-data-table>
                </div>
            </template>
            <p v-else>Not allowed to see this view</p>
        </div>

        <mercur-dialog :is-open.sync="dialog.isOpen">
            <template #header>
                <h3>{{ dialog.title }}</h3>
            </template>
            <div class="mb-1">{{ dialog.content }}</div>
            <template #footer>
                <div class="text-right">
                    <button class="btn" @click="dialog.isOpen = false">Cancel</button>
                    <button class="btn btn-primary" @click="dialog.action" :disabled="isLoading">Confirm</button>
                </div>
            </template>
        </mercur-dialog>

        <mercur-dialog :is-open.sync="logsDialog.isOpen" width="800px" @close="resetLogs()">
            <template #header>
                <h3>Task logs</h3>
            </template>
            <div class="mb-1 logs">
                <div class="row" v-for="(log, index) in logsDialog.logs" :key="log.pricingHistoryId">
                    <div class="col-12 d-inline-flex align-items-center">
                        <div class="log-index">
                            #{{ index + 1 }}
                        </div>
                        <div class="flex-grow-1 font-weight-bold">
                            <span v-if="log.pricingHistoryTitle">{{ log.pricingHistoryTitle }}</span>
                        </div>
                        <div class="text-grey">
                            <mercur-tooltip>
                                <template slot="label">{{log.dateCreated}}</template>
                                {{ log.dateCreated | asRelativeHumanTime }}
                            </mercur-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </mercur-dialog>

        <mercur-dialog :is-open.sync="newTaskDialog.isOpen" width="800px" @close="resetNewTask">
            <template #header>
                <h3>Create new task</h3>
            </template>
            <div class="mb-1">
                <div class="row mb-1" v-if="catalogProducts.length > 0">
                    <div class="col-12">
                        <pretty-select
                            placeholder="Catalog product"
                            customPlaceholder="Select an option"
                            v-model="catalogProduct"
                            :options="catalogProducts"
                            :get-option-label="item => item.productName"
                            :get-option-key="item => item.catalogProductId"
                            :prevent-text-wrap="true"
                            :shrunk-label="true"
                        ></pretty-select>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-12">
                        <pretty-select
                            placeholder="Catalog product variant"
                            customPlaceholder="Select an option"
                            v-model="catalogProductVariantId"
                            :options="catalogProductVariants"
                            :get-option-label="item => item.productName"
                            :get-option-key="item => item.catalogProductId"
                            :reduce="item => item.catalogProductId"
                            :prevent-text-wrap="true"
                            :shrunk-label="true"
                        ></pretty-select>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-12">
                        <country-selector
                            v-model="countryCode"
                            customPlaceholder="Select an option"
                            reducer="countryShortCode"
                            :shrunk-label="true"
                        />
                    </div>
                </div>
            </div>
            <template #footer>
                <div class="text-right">
                    <button class="btn" @click="newTaskDialog.isOpen = false">Cancel</button>
                    <button class="btn btn-primary" @click="createNewTask" :disabled="isLoading">Create task</button>
                </div>
            </template>
        </mercur-dialog>
    </div>
</template>

<script>
import CONFIG from '@root/config'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'
import PrettySelect from '@/components/utils/PrettySelect.vue'
import moment from 'moment'
import CountrySelector from '@/components/elements/CountrySelector.vue'

export default {
    name: 'SmartSpyView',
    components: { CountrySelector, MerchantDataTable, Pagination, PrettySelect },
    data () {
        return {
            url: CONFIG.API.ROUTES.SMART_SPY.OVERVIEW,
            catalogProducts: [],
            catalogProductVariants: [],
            catalogProductVariantsLookup: [],
            taskStatuses: [
                'PENDING_PRICING',
                'QUEUED_FOR_INPUT_GENERATION',
                'GENERATING_INPUT',
                'FAILED_INPUT_GENERATION',
                'AWAITING_PRICING',
                'IMPORTING',
                'RUNNING_PRICING',
                'FAILED_PRICING',
                'PRICED',
                'DONE',
                'CANCELED',
            ],
            catalogProduct: null,
            catalogProductVariantId: null,
            status: null,
            countryCode: null,
            isDialogOpen: false,
            activeData: null,
            pagination: {},
            amountOfDisplayedItems: 25,
            isLoading: false,
            dialog: {
                isOpen: false,
            },
            logsDialog: {
                isOpen: false,
                logs: [],
            },
            newTaskDialog: {
                isOpen: false,
            },
            options: {
                columns: {
                    'Product Configuration ID': {
                        field: 'productConfigurationId',
                        filter: 'agTextColumnFilter',
                        hide: true,
                    },
                    'Catalog product': {
                        field: 'catalogProductId',
                        filter: 'agTextColumnFilter',
                        sortable: false,
                        valueFormatter: ({ data }) => {
                            const catalogProduct = this.catalogProductVariantsLookup.find(item => item.catalogProductId === data.catalogProductId)
                            if (catalogProduct) {
                                return catalogProduct.productName
                            }

                            return 'Product not found'
                        },
                    },
                    'Country': {
                        field: 'countries',
                        filter: 'agTextColumnFilter',
                        sortable: false,
                        valueGetter: ({ data }) => {
                            const cacheData = JSON.parse(data.cacheData)
                            return cacheData.countries ? cacheData.countries[0] : 'N/A'
                        },
                        width: 100,
                    },
                    'Update ID': {
                        field: 'updateId',
                        filter: false,
                        sortable: false,
                        valueGetter: ({ data }) => {
                            const cacheData = JSON.parse(data.cacheData)
                            return cacheData.updateId || 'N/A'
                        },
                        width: 100,
                    },
                    'Status': {
                        field: 'taskStatus',
                        filter: 'agTextColumnFilter',
                        sortable: true,
                        statusChip: true,
                    },
                    'Created': {
                        field: 'dateCreated',
                        filter: false,
                        sortable: true,
                        width: 125,
                    },
                    'Updated': {
                        field: 'dateUpdated',
                        filter: false,
                        sortable: true,
                        width: 125,
                    },
                },
                sortModel: [
                    {
                        colId: 'dateUpdated',
                        sort: 'desc',
                    },
                ],
                actions: {
                    items: [
                        {
                            tooltip: 'Show logs',
                            icon: 'fas fa-file-alt',
                            onClick: ({ data }) => this.showLogs(data),
                            isDisabled: () => this.isLoading,
                            isHidden: () => !this.hasPermission('MerchantCentral/listSmartSpyTaskHistory'),
                        },
                        {
                            tooltip: 'Retry',
                            icon: 'fa fa-rotate-left',
                            onClick: ({ data }) => this.triggerRetryDialog(data),
                            isDisabled: ({ data }) => this.isLoading || !this.isRetryable(data),
                            isHidden: () => !this.hasPermission('MerchantCentral/retrySmartSpyTask'),
                        },
                    ],
                },
                rowModelType: 'serverSide',
                paginationType: 'custom',
                paginationPageSize: 25,
                cacheBlockSize: 25,
            },
        }
    },
    methods: {
        isShopSelected () {
            return this.selectedShop && this.selectedShop.applicationId !== '0'
        },
        loadCatalogProducts () {
            if (this.isShopSelected()) {
                this.get(CONFIG.API.ROUTES.CATALOG.LIST).then(({ data }) => {
                    this.$set(this, 'catalogProducts', data)
                })

                this.get(CONFIG.API.ROUTES.CATALOG.VARIANTS.LIST).then(({ data }) => {
                    this.$set(this, 'catalogProductVariantsLookup', data)
                })
            }
        },
        isRetryable (data) {
            const validStatus = [
                'FAILED_INPUT_GENERATION',
                'FAILED_PRICING',
                'FAILED_APPROVAL',
                'PRICED',
                'DONE',
            ].includes(data.taskStatus)
            const validCreationDate = moment().diff(moment(data.dateCreated), 'days') <= 30

            return validStatus && validCreationDate
        },
        triggerRetryDialog (data) {
            this.activeData = data
            this.dialog = {
                isOpen: true,
                title: 'Retry task',
                content: 'Are you sure that you want to retry this task? If the task is already DONE, just the sales prices import will run - no new prices will be generated.',
                action: this.retry,
            }
        },
        showLogs (data) {
            const url = CONFIG.API.ROUTES.SMART_SPY.LIST_HISTORY
            const payload = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))

            payload.request.filterModel.pricingEntityId = {
                filter: data.salesPriceTaskId,
                filterType: 'text',
                type: 'equals',
            }

            payload.request.sortModel.push({
                colId: 'dateCreated',
                sort: 'desc',
            })

            this.isLoading = true
            this.post(url, payload).then(({ data }) => {
                this.logsDialog.isOpen = true
                this.logsDialog.logs = data
            }).finally(() => {
                this.isLoading = false
            })
        },
        retry () {
            const url = CONFIG.API.ROUTES.SMART_SPY.RETRY_TASK
            this.isLoading = true

            this.post(url, { taskId: this.activeData.salesPriceTaskId }, 'The task was scheduled to be retried').then(() => {
                this.$refs.table.refreshGrid()
                this.resetDialog()
            }).finally(() => {
                this.isLoading = false
            })
        },
        createNewTask () {
            if (!this.catalogProductVariantId) {
                this.$root.$emit('notification:global', {
                    message: 'Please select a catalog product variant.',
                    type: 'error',
                })

                return
            }

            const url = CONFIG.API.ROUTES.SMART_SPY.CREATE_TASK
            this.isLoading = true

            const payload = {
                catalogProductId: this.catalogProductVariantId,
                country: this.countryCode,
            }

            this.post(url, payload, 'The task was scheduled to be generated').then(() => {
                this.$refs.table.refreshGrid()
                this.resetNewTask()
            }).finally(() => {
                this.isLoading = false
            })
        },
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },
        updateFilter (fieldId, value) {
            const filter = this.$refs.table.getFilter()

            if (!value) {
                delete filter[fieldId]
                this.$refs.table.applyFilter(filter)
                return
            }

            filter[fieldId] = {
                'type': 'equals',
                'filter': value,
                'filterType': 'text',
            }

            this.$refs.table.applyFilter(filter)
        },
        resetDialog () {
            this.dialog = {
                isOpen: false,
                title: null,
                content: null,
                action: null,
            }
        },
        resetLogs () {
            this.logsDialog = {
                isOpen: false,
                logs: [],
            }
        },
        resetNewTask () {
            this.newTaskDialog = {
                isOpen: false,
            }
        },
    },
    created () {
        this.loadCatalogProducts()
    },
    watch: {
        selectedShop () {
            this.loadCatalogProducts()
        },
        catalogProduct (value) {
            if (!value) {
                this.$set(this, 'catalogProductVariants', [])
                this.$set(this, 'catalogProductVariantId', null)
                this.updateFilter('productConfigurationId', null)
                return
            }

            const url = CONFIG.API.ROUTES.CATALOG.VARIANTS.LIST_BY_CATALOG_PRODUCT.replace('{catalogProductId}', value.catalogProductId)
            this.get(url).then(({ data }) => {
                this.$set(this, 'catalogProductVariants', data)
                this.$set(this, 'catalogProductVariantId', null)
            })

            this.updateFilter('productConfigurationId', value.productConfigurationId)
        },
        catalogProductVariantId (value) {
            this.updateFilter('catalogProductId', value)
        },
        countryCode (value) {
            this.updateFilter('countries', value)
        },
        status (value) {
            this.updateFilter('taskStatus', value)
        },
    },
}
</script>

<style lang="scss" scoped>
.logs {
    font-size: 12px;

    .log-index {
        width: 40px;
    }
}

.col-3+.col-3 {
    padding-left: 0px;
}
</style>
